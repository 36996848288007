/*
 * @Version: 2.0
 * @Author: chengweijia-tel:18702108979
 * @Date: 2022-12-13 11:11:11
 * @LastEditTime: 2023-07-05 17:30:10
 * @Description:
 */
import { reactive, ref, toRefs, onMounted, getCurrentInstance, ComponentInternalInstance, computed } from 'vue'
import { ZMessage, ZConfirm } from '@/utils/confirm'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from 'vant'

interface optionsItem {
  name: string
  icon: string
}
interface stateOption {
  id: any
  position: any
  shareVisible: boolean
  options: Array<optionsItem>
  posterShareVisible: boolean
  isDelivery: boolean
  isDialogClose: boolean
  deliveryStatus: boolean
  deliveryStatusTimer: any
  textToast: boolean
}

export default function postionDetailMixin(loginRef: any) {
  onBeforeRouteUpdate((to) => {
    loading.show()
    state.id = to.query.id
    getPositionDetail()
  })

  const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
  const router = useRouter()
  const route = useRoute()
  const store = useStore()
  const loading = proxy.$loading

  const state = reactive({
    id: null,
    position: {
      postNameOut: null,
      deptName: null,
      workPlaceName: null,
      positionOutTypeName: null,
      gmtModified: null,
      positionTypeId: null,
      channelId: null,
      isCollect: 0
    },
    shareVisible: false,
    posterShareVisible: false,
    isDelivery: false,
    deliveryStatus: false,
    deliveryStatusTimer: null,
    textToast: false
  }) as stateOption

  // 判断是否登录
  const userIsLogin = ref(localStorage.getItem('Authorization') ? true : false)

  function getUserInfo() {
    // console.log('getUserInfo', proxy)
    if (proxy.$isMobile && userIsLogin.value && !store.state.userInfo.nickName) {
      proxy.$http.auth
        .getUserInfo()
        .then((res: any) => {
          store.dispatch('setUserInfo', res)
        })
        .catch(() => {
          handleLoginOut()
        })
    }
  }

  function handleLoginOut() {
    localStorage.removeItem('Authorization')
    store.dispatch('setUserInfo', {})
    userIsLogin.value = false
  }

  /**
   * 获取职位详情
   *
   */
  const getPositionDetail = () => {
    proxy.$http.position
      .getPostInfoDetail({ id: state.id })
      .then((res: any) => {
        state.position = res
      })
      .finally(() => {
        loading.hide()
      })
  }

  /**
   * 投递简历
   *
   */
  const sendResume = () => {
    proxy.$http.position
      .checkDelivery({ postId: state.id })
      .then((res) => {
        // 一线岗位验证码登录
        if (state.position.channelId === 3) {
          state.isDelivery = true
          // state.deliveryStatus = true
          // changeDeliveryStatus()
          return
        }
        router.push({
          name: 'myResume',
          query: {
            postId: state.id
          }
        })
      })
      .catch((ex) => {
        return
      })
  }

  /**
   * 更改投递成功时状态
   */
  const changeDeliveryStatus = (type = null) => {
    state.deliveryStatus = !state.deliveryStatus
    // 手动关闭弹窗
    if (!state.deliveryStatus && type === 'delete') {
      clearTimeout(state.deliveryStatusTimer)
      state.deliveryStatusTimer = null
      state.textToast = true
      setTimeout(() => {
        state.textToast = false
      }, 3000)
    }
  }

  /**
   * 一线投递简历
   *
   */
  const handleSend = () => {
    const loginRefValue = loginRef.value
    // 如果未登录,进行登录，否则直接投递一线
    console.log('$isMobile', proxy.$isMobile)
    loginRefValue.handleSubmit(() => {
      const loading = proxy.$loading
      loading.show('投递中...')
      // 投递
      proxy.$http.position
        .blueDelivery({
          realName: loginRefValue.form.name,
          postId: state.id,
          phone: loginRefValue.form.phone,
          smsCode: loginRefValue.form.code
        })
        .then((res) => {
          console.log('投递成功，期待您加入中通大家庭！', res)
          state.isDelivery = false
          if (!proxy.$isMobile) {
            ZMessage({
              message: `投递成功，期待您加入中通大家庭！`,
              type: 'success'
            })
          } else {
            // H5未登录，手机号与当前登录手机号不同，不会显示完善简历按钮
            state.isDelivery = false
            // 未登录状态下，一线投递成功自动登录
            if (!localStorage.getItem('Authorization')) {
              store.dispatch('setAuthorization', res.token)
              store.dispatch('setRedirectUrl', null)
            }
            if (store.state.userInfo && store.state.userInfo.mobile === loginRefValue.form.phone) {
              state.deliveryStatus = true
              state.deliveryStatusTimer = setTimeout(() => {
                state.deliveryStatus = false
              }, 5000)
            } else {
              // state.displayBtn = false
              ZMessage({
                message: `投递成功，期待您加入中通大家庭！`,
                type: 'success'
              })
            }
          }
        })
        .finally(() => {
          loading.hide()
        })
    })
  }

  /**
   * 一线取消投递简历
   *
   */
  const handleClose = () => {
    state.isDelivery = false
    if (state.deliveryStatus) {
      state.deliveryStatus = false
    }
  }

  /**
   * 收藏职位
   *
   */
  const cancelOrCollectPost = (operationType: string) => {
    if (judgeIsLogin(`收藏职位`)) {
      proxy.$http.position.cancelOrCollectPost({ id: state.id, operationType }).then((res: any) => {
        state.position.isCollect = !state.position.isCollect
      })
    }
  }

  /**
   * 判断登录状态
   *
   */
  const judgeIsLogin = (value: string) => {
    if (!localStorage.getItem('Authorization')) {
      ZConfirm(`登录用户才能${value}`, 'Warning').then(() => {
        store.dispatch('setRedirectUrl', route.fullPath)
        router.push({
          name: 'Login'
        })
      })
      return false
    }
    return true
  }

  /**
   * 打开工作地点地图
   */
  const handleMapOpen = () => {
    if (state.position.baiduMapUrl) {
      window.open(state.position.baiduMapUrl, '_blank')
    }
  }

  onMounted(() => {
    state.id = router.currentRoute.value.query.id
    state.isDelivery = false
    getPositionDetail()
    console.log('proxy', proxy.$isMobile)
    getUserInfo()
  })

  loading.show()
  return {
    ...toRefs(state),
    sendResume,
    cancelOrCollectPost,
    handleSend,
    handleClose,
    handleMapOpen,
    changeDeliveryStatus
  }
}
