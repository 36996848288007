<!--
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-03-02 13:57:29
 * @LastEditTime: 2022-03-02 18:06:54
-->
<template>
  <div class="empty">
    <img src="@/assets/error/empty.png" />
    <slot></slot>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
.empty {
  padding: 50px 0 100px;
  text-align: center;
  font-size: 12px;
  color: #abb3be;
  img {
    width: 100px;
    display: block;
    margin: 0 auto;
  }
}
</style>
