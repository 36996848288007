
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  getCurrentInstance,
  ComponentInternalInstance,
  ref,
  nextTick
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import html2canvas from 'html2canvas'
import { handleDownload } from '@/utils/download'

interface stateOption {
  swiperVisible: boolean
  posterList: any
  imgUrl: any
  imgUrlShow: boolean
  posterBackground: any
  qrcode: any
  isMi: boolean
}

export default defineComponent({
  props: ['postInfo'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const imageRef = ref(null)
    const imgUrlRef = ref(null)

    const state = reactive({
      swiperVisible: true,
      posterList: [],
      imgUrl: null,
      imgUrlShow: false,
      postInfo: props.postInfo,
      posterBackground: null,
      qrcode: null,
      isMi: false
    }) as stateOption

    /**
     * 获取海报
     *
     */
    const handlePosterGet = () => {
      const params = {
        type: '6',
        recruitingType: props.postInfo.channelId,
        pageNum: 1,
        pageSize: 10,
        status: 1,
        posterGenerationChannel: 1
      }
      proxy.$http.campus.getPCActivityByPage(params).then((res) => {
        state.posterList = res.list
        state.posterBackground = state.posterList[0].photoUrl
      })
    }

    /**
     * 获取二维码
     */
    const url2QRcode = () => {
      const host = `${location.origin}${location.pathname}`
      const url = `${host}?id=${props.postInfo.id}`
      proxy.$http.base.url2QRcode({ url }).then((res) => {
        console.log(res)
        state.qrcode = res.link
      })
    }

    /**
     * 生成海报
     *
     */
    const handlePosterSelect = () => {
      const loading = proxy.$loading
      loading.show('生成中...')
      html2canvas(imageRef.value, {
        useCORS: true // 使用跨域
      })
        .then((canvas) => {
          state.imgUrl = canvas.toDataURL('image/png')
          imgUrlRef.value.onload = () => {
            state.imgUrlShow = true
            loading.hide()
          }
          // Notify({ type: 'primary', message: '长按图片可保存或分享' })
        })
        .catch(() => {
          loading.hide()
        })
    }

    /**
     * 关闭海报
     *
     */
    const handlePosterClose = () => {
      state.imgUrl = null
      state.imgUrlShow = false
    }

    /**
     * swiper滚动
     *
     * @param {Number} index 索引
     */
    const handleSwipeChange = (index) => {
      state.posterBackground = state.posterList[index].photoUrl
    }

    const handlePosterDownload = () => {
      handleDownload(state.imgUrl)
    }

    onMounted(() => {
      handlePosterGet()
      url2QRcode()
      state.isMi = navigator.userAgent.indexOf('MiuiBrowser') != -1
      console.log(`state.isMi`, state.isMi)
    })

    return {
      ...toRefs(state),
      handlePosterSelect,
      handlePosterClose,
      handleSwipeChange,
      handlePosterDownload,
      imageRef,
      imgUrlRef
    }
  }
})
