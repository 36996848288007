import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pc_position_detail = _resolveComponent("pc-position-detail")!
  const _component_h5_position_detail = _resolveComponent("h5-position-detail")!

  return (!_ctx.$isMobile)
    ? (_openBlock(), _createBlock(_component_pc_position_detail, {
        key: 0,
        type: "social"
      }))
    : (_openBlock(), _createBlock(_component_h5_position_detail, {
        key: 1,
        type: "social"
      }))
}