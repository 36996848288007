
import { defineComponent } from 'vue'
import PcPositionDetail from '@/views/position-detail/position-detail-pc.vue'
import h5PositionDetail from '@/views/position-detail/position-detail-h5.vue'
export default defineComponent({
  name: 'positionDetail',
  components: {
    PcPositionDetail,
    h5PositionDetail
  }
})
