/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2020-11-28 18:04:12
 * @LastEditTime: 2022-06-21 16:05:34
 */
import { saveAs } from 'file-saver'
function getBlob(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      }
    }
    xhr.send()
  })
}
export function handleDownload(url, name) {
  getBlob(url).then((blob) => {
    saveAs(blob, name)
  })
  return false
}
