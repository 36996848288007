
import { Options, Vue } from 'vue-class-component'

interface FormOption {
  name: string | null
  phone: string | null
  code: string | null
  verification: string
}

@Options({
  watch: {
    form: {
      deep: true,
      handler: function () {
        const phoneIsValid = this.form.phone && /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(this.form.phone)
        const codeIsValid = this.form.code && /^\d{4}$/.test(this.form.code)
        if (phoneIsValid && codeIsValid) {
          this.loginButtonIsDisabled = false
        } else {
          this.loginButtonIsDisabled = true
        }
      }
    }
  }
})
export default class Login extends Vue {
  private $store!: any
  public $http!: any
  public $dayjs!: any
  public $isMobile!: boolean

  public $refs!: any

  // const formRef = ref<FormInstance>()

  form: FormOption = {
    name: null,
    phone: null,
    code: null,
    verification: null
  }

  // 验证滑块ref
  slideblockRef!: any

  // 显示验证码气泡
  verifyCodePopoverVisible = false

  // 禁用登录按钮
  loginButtonIsDisabled = true

  // 上级页面
  historyRoutefullPath?: string = ''

  // 60秒倒计时
  codeCount = 0

  // 倒计时显示
  countIsVisible = false

  src: any = null

  timer: any = null

  codeImage: string = null
  generatorId: string = null

  phoneDisabled = false

  rules = {
    name: [{ required: true, message: '姓名不能为空', trigger: 'change' }],
    phone: [
      { required: true, message: '手机号码不能为空', trigger: 'change' },
      { pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '手机号码格式不正确' }
    ],
    code: [
      { required: true, message: '验证码不能为空', trigger: 'change' },
      { pattern: /^\d{4}$/, message: '验证码格式不正确' }
    ],
    verification: [
      { required: true, message: '验证码不能为空', trigger: 'change' },
      { pattern: /^\d{4}$/, message: '验证码格式不正确' }
    ]
  }

  mounted() {
    console.log(`mounted`)
    // this.historyRoutefullPath = this.$store.state.redirectUrl
    this.getVerificationImg()
    console.log('仓库', this.$store.state)
    if (this.$store.state.userInfo.mobile) {
      this.form.phone = this.$store.state.userInfo.mobile
      if (localStorage.getItem('Authorization')) {
        this.phoneDisabled = true
      } else {
        this.phoneDisabled = false
      }
    }
    this.form.name = null
    this.form.code = null
    this.form.verification = null
  }

  // 点击获取验证码
  handleVerifyClick() {
    if (!this.$isMobile) {
      this.$refs['formRef'].validateField('phone', (valid: any) => {
        if (!valid) {
          this.$refs['formRef'].validateField('verification', (errorMsg: any) => {
            if (!errorMsg) {
              this.getVerifyCode()
            }
          })
        }
      })
    } else {
      this.$refs['formRef'].validate(['phone', 'verification']).then(() => {
        this.getVerifyCode()
      })
    }
  }

  // 获取验证码
  getVerifyCode() {
    let data = {
      phone: this.form.phone,
      generatorId: this.generatorId,
      code: this.form.verification
    }
    this.$http.auth
      .smsSend(data)
      .then((res: any) => {
        const TIME_COUNT = 60
        if (!this.timer) {
          this.codeCount = TIME_COUNT
          this.countIsVisible = true
          this.timer = setInterval(() => {
            if (this.codeCount > 0 && this.codeCount <= TIME_COUNT) {
              this.codeCount--
            } else {
              this.countIsVisible = false
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      })
      .catch((res: any) => {
        this.getVerificationImg()
        this.form.verification = null
      })
  }

  // 验证码登录
  handleSubmit(cb) {
    if (this.$isMobile) {
      this.$refs['formRef'].validate().then(() => {
        cb()
      })
    } else {
      this.$refs['formRef'].validate((valid: any) => {
        if (valid) {
          cb()
        }
      })
    }
  }

  // 获取图形验证码
  getVerificationImg() {
    this.$http.auth.getVerificationImg().then((res) => {
      this.codeImage = res.codeImage
      this.generatorId = res.generatorId
    })
  }
}
