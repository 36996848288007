
import { defineComponent, reactive, toRefs, getCurrentInstance, ComponentInternalInstance, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import ZEmpty from '@/components/layout/z-empty.vue'
import ZQrcode from '@/components/layout/z-qrcode.vue'

interface stateOption {
  positionList: any
}

export default defineComponent({
  components: {
    ZEmpty,
    ZQrcode
  },
  props: {
    positionId: Number,
    positionTypeId: String,
    channelId: Number
  },
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()
    const { positionId, positionTypeId, channelId } = toRefs(props)

    const state = reactive({
      positionList: []
    }) as stateOption

    watch(positionId, (nv, ov) => {
      positionId.value = nv
      getPositionList()
    })

    const getPositionList = () => {
      proxy.$http.position
        .getRecommendPostList({
          id: Number(positionId.value),
          positionTypeId: positionTypeId.value,
          postType: channelId.value,
          pageNum: 1,
          pageSize: 6
        })
        .then((res: any) => {
          state.positionList = res
          emit('loading', false)
        })
    }

    onMounted(() => {
      getPositionList()
    })

    const handleJobClick = (item: any) => {
      router.push({
        name: 'PositionDetail',
        query: {
          id: item.id
        }
      })
    }

    return {
      ...toRefs(state),
      handleJobClick
    }
  }
})
