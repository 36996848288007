<!--
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-03-02 17:14:49
 * @LastEditTime: 2022-12-27 17:56:51
-->
<template>
  <div class="z-card">
    <img src="@/assets/qrcode.jpg" class="qrcode" />
    <div class="qrcode-text">
      关注
      <span>"中通招聘"</span>
      <br />
      了解更多动态
    </div>
    <div class="qrcode__footer">
      <img src="@/assets/qrcode-bg.png" class="qrcode-bg" />
      <img src="@/assets/courier.png" class="courier" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.z-card {
  cursor: pointer;
  user-select: none;
  width: 100%;
  min-width: 160px;
  background: #ffffff;
  border-radius: 11px;
  position: relative;
  padding: 15px 0 0;
  text-align: center;
  .qrcode {
    width: 120px;
    border: 5px solid #e0e8ef;
    border-radius: 15px;
    -webkit-user-drag: none;
  }
  .qrcode-text {
    font-size: 16px;
    font-weight: 600;
    padding: 9px 0 0;
    span {
      color: #3693ff;
    }
  }
  .qrcode__footer {
    position: relative;
    height: 50px;
    .qrcode-bg {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-user-drag: none;
    }
    .courier {
      width: 40px;
      height: 40px;
      right: 16px;
      text-align: right;
      bottom: 2px;
      position: absolute;
    }
  }
}
</style>
